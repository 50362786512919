import request from '@/utils/request'


// 查询用户等级规则列表
export function listLevelRules(query) {
  return request({
    url: '/platform/user-level-rules/list',
    method: 'get',
    params: query
  })
}

// 查询用户等级规则分页
export function pageLevelRules(query) {
  return request({
    url: '/platform/user-level-rules/page',
    method: 'get',
    params: query
  })
}

// 查询用户等级规则详细
export function getLevelRules(data) {
  return request({
    url: '/platform/user-level-rules/detail',
    method: 'get',
    params: data
  })
}

// 新增用户等级规则
export function addLevelRules(data) {
  return request({
    url: '/platform/user-level-rules/add',
    method: 'post',
    data: data
  })
}

// 修改用户等级规则
export function updateLevelRules(data) {
  return request({
    url: '/platform/user-level-rules/edit',
    method: 'post',
    data: data
  })
}

// 删除用户等级规则
export function delLevelRules(data) {
  return request({
    url: '/platform/user-level-rules/delete',
    method: 'post',
    data: data
  })
}
